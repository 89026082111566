import React from "react"
import styled from "styled-components"
import { ContentWrapper } from "../components/common"

import Layout from "../components/layout"
import SEO from "../components/seo"
import theme from "../utils/theme"

const LinkWrapper = styled.a`
  display: block;
  text-align: center;
  color: ${theme.colors.darkerBlue};
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Sisältöä ei löydy" />
    <ContentWrapper>
      <h1>Sisältöä ei löytynyt</h1>
      <p style={{ textAlign: "center" }}>Sivua ei valitettavasti löydy sivultamme.</p>
      <LinkWrapper href="/">Takaisin etusivulle</LinkWrapper>
    </ContentWrapper>
  </Layout>
)

export default NotFoundPage
